import Axios from 'axios'
import { SiteConfig } from '../types/'
import Querystring from 'querystring'

export default (email: string, config: SiteConfig): Promise<any> => {
    const data = Querystring.stringify({ email })
    return Axios({
        url: config.CMS_ENDPOINT + '/api/subscribeUserWithEmail.php',
        method: 'POST',
        data,
        headers: {
            Accept: 'application/x-www-form-urlencoded',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}
