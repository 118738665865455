import React, { useState } from 'react'

interface HyperLinkProps {
    label: string
    href?: string | false
    className?: string
    style: {}
    onHoverStyle?: {}
    newTab?: string
}

export default ({
    label,
    href,
    className,
    style = {},
    onHoverStyle = {},
    newTab,
}: HyperLinkProps) => {
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <a
            href={href ? href : '#'}
            target={newTab}
            className={`common hyper-link ${className}`}
            style={onHover ? onHoverStyle : style}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            {label}
        </a>
    )
}
