import React, { useState } from 'react'
import './style.css'

interface ButtonProps {
    id?: string
    style?: any
    onHoverStyle?: any
    active?: boolean
    className?: string
    label: string
    onClick: Function
}

export default ({
    style,
    onHoverStyle,
    active,
    label,
    className = '',
    onClick,
    id = '',
}: ButtonProps) => {
    const [_style, setStyle] = useState(style)
    return (
        <div
            id={id}
            className={`common button ${className}`}
            style={active ? onHoverStyle : _style}
            onClick={() => onClick()}
            onMouseEnter={() => setStyle(onHoverStyle)}
            onMouseLeave={() => setStyle(style)}
        >
            <span>{label}</span>
        </div>
    )
}
