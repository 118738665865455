import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../../context/theme'
import { Link } from 'gatsby'

interface Props {
    label: string
    link: string
}

export default ({ label, link }: Props) => {
    const theme = useContext(ThemeContext)
    const [onHover, setOnHover] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(false)
    useEffect(() => {
        if (
            typeof window !== `undefined` &&
            typeof window.location !== `undefined`
        )
            setActive(window.location.pathname.trim() === link)
    }, [])
    return (
        <li>
            <Link
                to={link}
                style={{
                    color:
                        onHover || active
                            ? theme.color.primary
                            : theme.color.secondary,
                    borderBottom:
                        onHover || active
                            ? `1px solid ${theme.color.primary}`
                            : '1px solid transparent',
                }}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
            >
                {label}
            </Link>
        </li>
    )
}
