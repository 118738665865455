import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeContext } from '../../../context/theme'

import './style.css'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface IconButtonProps {
    icon: IconDefinition
    className?: string
    onClick: Function
}

export default ({ icon, onClick, className }: IconButtonProps) => {
    const theme = useContext(ThemeContext)
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <div className={`common icon-button ${className}`}>
            <FontAwesomeIcon
                icon={icon}
                style={{
                    color: onHover
                        ? theme.color.secondary
                        : theme.color.primary,
                }}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onClick={() => onClick()}
            />
        </div>
    )
}
