import React, { useContext, createContext, ReactNode } from 'react'

interface State {
    color: {
        primary: string
        secondary: string
        third: string
    }
}
type ThemeProviderProps = { children: ReactNode; value?: State }

const defaultTheme: State = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

export const ThemeContext = createContext<State | undefined>(undefined)
export const ThemeProvider = ({ children, value }: ThemeProviderProps) => {
    return (
        <ThemeContext.Provider value={value ? value : defaultTheme}>
            {children}
        </ThemeContext.Provider>
    )
}
export const useThemeContext = () => {
    const context = useContext(ThemeContext)
    if (!context) throw new Error('State must be used within a provider')
    return context
}
