import React from 'react'
import './style.css'
import { Room } from '../../../types/HomeDesigns'

const bedroomIcon = require('./icon-bedroom.png')
const bathroomIcon = require('./icon-bathroom.png')
const garageIcon = require('./icon-garage.png')
const livingIcon = require('./icon-living.png')

const rooms: string[] = [bedroomIcon, livingIcon, bathroomIcon, garageIcon]

interface RoomProps {
    room: Room
}

export default ({ room }: RoomProps) => (
    <li className="common room">
        <div className="room-count">{room.count}</div>
        <div
            className="room-image"
            style={{ backgroundImage: `url(${rooms[room.type]})` }}
        />
    </li>
)
