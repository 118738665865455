import React, { useState } from 'react'
import { Theme } from '../../../types/'
import Button from '../../Common/Button'
import './style.css'

interface Props {
    props: {
        placeholder: string
        button: {
            text: string
            address: string
        }
        consent: string
    }
    id?: string
    onSubmit: Function
    loading: boolean
    style?: {
        input?: any
        button?: any
    }
    theme: Theme
}

export default ({ props, style, onSubmit, loading, theme, id = '' }: Props) => {
    const [field, setField] = useState<string>('')
    const [checkbox, setCheckbox] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const buttonStyle = style && style.button ? style.button : null
    let onHoverStyle = { ...buttonStyle }
    onHoverStyle.backgroundColor = theme.color.primary

    return (
        <div className="common call-to-action row">
            <div className="col-md-8">
                <label
                    htmlFor={`input-${props.button.text}`}
                    style={{ width: 0, height: 0, visibility: 'hidden' }}
                />
                <input
                    id={`input-${props.button.text}`}
                    placeholder={props.placeholder}
                    value={field}
                    onChange={event => setField(event.target.value)}
                    style={style && style.input ? style.input : null}
                />
                <p>
                    <label
                        htmlFor={`checkbox-${props.button.text}`}
                        style={{ width: 0, height: 0, visibility: 'hidden' }}
                    />
                    <input
                        id={`checkbox-${props.button.text}`}
                        type="checkbox"
                        checked={checkbox}
                        onChange={() => setCheckbox(!checkbox)}
                    />
                    {props.consent}
                </p>
            </div>
            <div className="col-md-4">
                <Button
                    id={id}
                    onClick={() => {
                        if (checkbox) {
                            onSubmit(field)
                            setError(false)
                        } else setError(true)
                    }}
                    label={loading && !error ? 'Loading...' : props.button.text}
                    style={buttonStyle}
                    onHoverStyle={onHoverStyle}
                />
                {error ? (
                    <span style={{ color: '#b50f0f' }}>
                        Please accept the consent form
                    </span>
                ) : null}
            </div>
        </div>
    )
}
