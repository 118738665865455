import React, { useState } from 'react'
import Button from '../Common/Button'
import { ThemeOptions } from '../../types'
import { Link } from 'react-scroll'

interface Props {
    label: string
    link: string
    options?: ThemeOptions
    style?: any
    onHoverStyle?: any
    siteLink?: boolean
}

export default ({
    label,
    link,
    options,
    style,
    onHoverStyle,
    siteLink,
}: Props) => {
    if (siteLink) {
        const [onHover, setOnHover] = useState<boolean>(false)
        return (
            <li style={{ cursor: 'pointer' }}>
                <Link
                    style={
                        onHover
                            ? onHoverStyle
                                ? onHoverStyle
                                : {
                                      color: 'white',
                                      backgroundColor:
                                          options.theme.color.primary,
                                      padding: '8px 35px',
                                      border: `1px solid ${options.theme.color.primary}`,
                                  }
                            : style
                            ? style
                            : {
                                  color: 'white',
                                  backgroundColor:
                                      options.theme.color.secondary,
                                  padding: '8px 35px',
                                  border: `1px solid ${options.theme.color.secondary}`,
                              }
                    }
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                    to="form-dropdown"
                    className="enquire-button"
                    offset={0}
                    smooth={true}
                    duration={1000}
                >
                    {label}
                </Link>
            </li>
        )
    }
    return (
        <li>
            <Button
                onClick={() => window.open(link)}
                style={
                    style
                        ? style
                        : {
                              color: 'white',
                              backgroundColor: options.theme.color.secondary,
                              padding: '8px 35px',
                              border: `1px solid ${options.theme.color.secondary}`,
                          }
                }
                onHoverStyle={
                    onHoverStyle
                        ? onHoverStyle
                        : {
                              color: 'white',
                              backgroundColor: options.theme.color.primary,
                              padding: '8px 35px',
                              border: `1px solid ${options.theme.color.primary}`,
                          }
                }
                label={label}
            />
        </li>
    )
}
