import {
    HighlightDTO,
    VirtualTourDTO,
    GalleryDTO,
    Image,
    FlyerDTO,
    FloorplanDTO,
} from '.'
import { Houses } from './ExternalApi'

export enum RoomType {
    Bedroom,
    LivingRoom,
    Bathroom,
    Garage,
}

export type Room = {
    type: RoomType
    count: number
}

export type RoomList = {
    bedroom?: Room
    living_room?: Room
    bathroom?: Room
    garage?: Room
}

export type House = {
    flyer?: FlyerDTO | false
    package_only?: boolean
    id: string
    name: string
    description: string
    backgroundImage: string
    suburb: string
    price: string
    rooms: RoomList
    storeys: 'Single' | 'Double'
    floorplan: FloorplanDTO | false
    size: {
        size: number
        lotWidth: number
        lotDepth: number
    }
}

export interface HomeDesign extends House {
    highlights: HighlightDTO[] | false
    virtualTour: VirtualTourDTO | false
    gallery: GalleryDTO | false
    design_name: string
    design_size: string
}

export interface HousePackage extends House {
    lotNumber: number
    streetName: string
    previewImage: string
    estateName: string

    deepLink: string
    location: {
        lat: number
        lng: number
    }
}

export type HomeDesignRequest = {
    slug: string
    title: {
        rendered: string
    }
    image: string
    image_optimised: string
    acf: {
        garages: string
        bathrooms: string
        living: string
        bedrooms: string
        region: string
        house_size: string
        min_lot_depth: string
        min_lot_width: string
        stripped_content: string
        storeys: 'Single' | 'Double'
        price: string
        design_name: string
        design_size: string
        floorplan_g: {
            id: number
            url: string
        }
        floorplan_1?: {
            url: string
        }
        blocks?: {
            title: string
            text: string
            image: {
                url: string
            }
        }[]
        virtual_tour?: {
            title: string
            image: {
                url: string
            }
            url: string
        }
        gallery?: Image[]
        flyer?: {
            url: string
        }
        estate_name: string
        package_only: boolean
    }
}

export interface HousePackageRequest extends Houses {}
