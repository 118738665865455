import React from 'react'
import RoomComponent from '../Room'
import './style.css'
import { Theme } from '../../../types'
import { RoomList } from '../../../types/HomeDesigns'

interface RoomListProps {
    rooms: RoomList
    options?: Theme
}

export default ({ rooms, options }: RoomListProps) => (
    <div className="rooms-container">
        <ul>
            {rooms.bedroom ? <RoomComponent room={rooms.bedroom} /> : null}
            {rooms.living_room ? (
                <RoomComponent room={rooms.living_room} />
            ) : null}
            {rooms.bathroom ? <RoomComponent room={rooms.bathroom} /> : null}
            {rooms.garage ? <RoomComponent room={rooms.garage} /> : null}
        </ul>
    </div>
)
