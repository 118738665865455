import React, { useContext } from 'react'
import './style.css'
import { navigate } from 'gatsby'
import { ThemeContext } from '../../context/theme'

interface FooterProps {
    background: {
        image: string
        color: string
    }
    privacy: {
        text: string
        link: string
    }
}

export default ({ background, privacy }: FooterProps) => {
    const theme = useContext(ThemeContext)
    return (
        <footer
            className="background"
            style={{ backgroundColor: background.color }}
        >
            <img
                src={background.image}
                onClick={() => navigate('/')}
                alt={'footer background image'}
            />
            <div className="footer-links">
                <a
                    href="tel: 1800 767 837"
                    style={{ color: theme.color.secondary }}
                >
                    1800 767 837
                </a>
                <a href={privacy.link} target="_blank" rel="noopener">
                    <span style={{ color: theme.color.secondary }}>
                        {privacy.text}
                    </span>
                </a>
                <a
                    href="https://porterdavis.com.au/disclaimer/"
                    target="_blank"
                    rel="noopener"
                >
                    <span style={{ color: theme.color.secondary }}>
                        Terms & Conditions
                    </span>
                </a>
            </div>
        </footer>
    )
}
