/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Footer from './Footer'
import './layout.css'
import './main.css'
import Navigation from './Navigation'

const footerProps = {
    background: {
        image:
            'https://adminpdx.sonardev.com.au/app/uploads/2019/12/Logo-PDX-Full-Rev.png',
        color: '#001689',
    },
    privacy: {
        link: 'https://porterdavis.com.au/privacy-policy/',
        text: 'Privacy Policy',
    },
}

const Layout = ({ children }) => {
    return (
        <>
            <Navigation />
            <div style={{ paddingTop: 50 }}>
                <main>{children}</main>
                <Footer
                    background={footerProps.background}
                    privacy={footerProps.privacy}
                />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
