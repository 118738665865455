import React from 'react'
import { NavigationItemDTO, DisplayType } from './types'
import NavigationItem from './NavigationItem'
import { ThemeOptions } from '../../types'
import NavigationItemButton from './NavigationItemButton'

interface NavigationListProps {
    navigationItem: NavigationItemDTO[]
    displayType: DisplayType
    options: ThemeOptions
}

export default ({
    navigationItem,
    displayType,
    options,
}: NavigationListProps) => (
    <div
        className={
            displayType === DisplayType.Desktop
                ? 'desktop'
                : displayType === DisplayType.Mobile
                ? 'mobile'
                : ''
        }
    >
        <ul>
            {navigationItem.map((item, index) => (
                <NavigationItem label={item.label} link={item.link} />
            ))}
            <NavigationItemButton
                label={'Shop Now'}
                link={'https://shop.pdxhomes.com.au/'}
                options={options}
            />
            <NavigationItemButton
                label={'Register'}
                link={''}
                options={options}
                siteLink={true}
                style={{
                    color: options.theme.color.secondary,
                    border: `1px solid ${options.theme.color.secondary}`,
                    padding: '8px 35px',
                }}
            />
        </ul>
    </div>
)
