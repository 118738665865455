import React, { useState, useContext } from 'react'
import './style.css'
import NavigationList from './NavigationList'
import { NavigationItemDTO, DisplayType } from './types'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../../context/theme'
import IconButton from '../Common/IconButton'

const logo = require('../../images/logo.png')

const navigationItem: NavigationItemDTO[] = [
    { label: 'Help & Advice', link: '/first-home-buyer-advice' },
    { label: 'Home Designs', link: '/home-designs' },
    { label: 'H&L Packages', link: '/house-and-land-packages' },
]

export default () => {
    const theme = useContext(ThemeContext)
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false)
    const [opacity, setOpacity] = useState<number>(0)
    const changeDisplayMobileMenu = (show: boolean) => {
        if (show) {
            setDisplayMobileMenu(true)
            setOpacity(1)
        } else {
            setOpacity(0)
            setTimeout(() => setDisplayMobileMenu(false), 500)
        }
    }
    return (
        <section className="navigation">
            <a href="/">
                <img src={logo} height={40} alt={'home button'} />
            </a>
            <NavigationList
                navigationItem={navigationItem}
                displayType={DisplayType.Desktop}
                options={{ theme }}
            />
            {/* <Search /> */}
            <div></div>
            <div className="mobile-link-container">
                <IconButton
                    icon={faBars}
                    onClick={() => changeDisplayMobileMenu(true)}
                    className="hamburger"
                />
                <div
                    className="mobile-link-container-content"
                    style={{
                        visibility: displayMobileMenu ? 'visible' : 'hidden',
                        opacity,
                    }}
                    onClick={() => changeDisplayMobileMenu(false)}
                >
                    <IconButton
                        icon={faTimes}
                        onClick={() => changeDisplayMobileMenu(false)}
                        className="close"
                    />
                    <NavigationList
                        navigationItem={navigationItem}
                        displayType={DisplayType.Mobile}
                        options={{ theme }}
                    />
                </div>
            </div>
        </section>
    )
}
