import React, { useState } from 'react'
import Input from '../Common/Input'
import { SiteConfig } from '../../types/'
import { Theme } from '../../types'
import './style.css'
import { getFontSize } from '../../utils'
import subscribeUserWithEmail from '../../utils/subscribeUserWithEmail'
import { navigate } from 'gatsby'

interface Props {
    theme: Theme
    config: SiteConfig
}

const callToAction = {
    placeholder: 'Your email address...',
    button: {
        text: 'Subscribe',
        address: '#',
    },
    consent:
        'I consent to receive information regarding special offers, events and products.',
}
const headerText = 'Stay in the loop'

export default ({ theme, config }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const onSubmit = (email: string) => {
        setLoading(true)
        subscribeUserWithEmail(email, config)
            .then(() => {
                navigate('/thank-you')
            })
            .catch(error => {
                console.log('Failed')
                console.error(error)
            })
            .finally(() => setLoading(false))
    }
    return (
        <section
            className="subscribe-form form-dropdown"
            style={{ backgroundColor: theme.color.primary }}
        >
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-3 header-text"
                        style={{
                            fontFamily: 'Big Points',
                            color: 'white',
                            fontSize: getFontSize('Big Points', 20, 40),
                        }}
                    >
                        {headerText}
                    </div>
                    <div className="col-lg-9">
                        <Input
                            props={callToAction}
                            id={'form-submit-subscribe'}
                            theme={theme}
                            onSubmit={onSubmit}
                            loading={loading}
                            style={{
                                input: {
                                    color: 'white',
                                },
                                button: {
                                    backgroundColor: theme.color.secondary,
                                    color: 'white',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
